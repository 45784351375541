import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, ParamMap, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { filter, pairwise, pluck, tap } from 'rxjs/operators';
import { environment as env, environment } from 'src/environments/environment';
import { AuthService } from './core/auth/services/auth.service';
import { MetaService } from './core/meta.service';
import { PixelPartnersService } from './pixel-partners.service';
import { UrlService } from './shared/url/url.service';
@Component({
  selector: 'app-root',
  template: `
    <ng-container *ngIf="!!translatesReady">
      <router-outlet></router-outlet>
    </ng-container>
    <ngx-spinner type="ball-clip-rotate-multiple" style="z-index:9999; position: fixed;"></ngx-spinner>
  `,
})
export class AppComponent implements OnInit {
  previousUrl: string = null;
  currentUrl: string = null;
  private _routeScrollPositions: { [url: string]: number }[] = [];
  translatesReady = false;

  production = env.production;

  constructor(
    private router: Router,
    private urlService: UrlService,
    private translateService: TranslateService,
    private metaService: MetaService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private pixelPartnersService: PixelPartnersService,
    private spinner: NgxSpinnerService
  ) {
    translateService.addLangs(['IT']);
    translateService.setDefaultLang('IT');
    translateService.use('IT');
    console.log('_____________________ SGB-WEB VERSION 2 ____________________');
  }

  ngOnInit() {
    this.spinner.show();
    this.translateService.get('MAPPINGS.provinces.AG').subscribe({
      next: () => {
        this.translatesReady = true;
      },
      complete: () => this.spinner.hide(),
    });

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.previousUrl = this.currentUrl;
      this.currentUrl = event.url;
      this.urlService.setPreviousUrl(this.previousUrl);
      this.pixelPartnersService.loadAdForm();
    });

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        pluck('urlAfterRedirects'),
        tap((data: string) => this.metaService.updateMeta(data))
      )
      .subscribe();

    this.router.events.pipe(pairwise()).subscribe(([prevRouteEvent, currRouteEvent]) => {
      if (prevRouteEvent instanceof NavigationEnd && currRouteEvent instanceof NavigationStart) {
        this._routeScrollPositions[prevRouteEvent.url] = window.scrollY;
      }
      if (currRouteEvent instanceof NavigationEnd) {
        document.body.scrollTo(0, this._routeScrollPositions[currRouteEvent.url] || 0);
      }
    });

    this.route.queryParamMap
      .pipe(
        tap((params: ParamMap) => {
          if (params.has('utm_campaign')) this.authService.setReferralDataSessionStorage(params);
        })
      )
      .subscribe(params => {
        if (params.has('privacy')) {
          switch (params.get('privacy')) {
            case 'terms':
              this.handleTerms();
              break;
            case 'general':
              this.handleGeneral();
              break;
            case 'cookie':
              this.handleCookie();
          }
        } else return;
      });
  }

  handleTerms() {
    window.location.href = `${environment.privacy.term}`;
  }

  handleGeneral() {
    window.location.href = `${environment.privacy.policy}`;
  }

  handleCookie() {
    window.location.href = `${environment.privacy.cookie}`;
  }
}
