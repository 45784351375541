import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TuiDialogService } from '@taiga-ui/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import {
  BaseInput,
  CheckboxInput,
  Form,
} from 'src/app/shared/form';
import { environment } from 'src/environments/environment';

import {
  PaymentTypeEnum
} from '../../../../pages/private/pages/company/pages/messages/enum/payment-type';
import { UserType } from 'src/app/core/interfaces/user-type.enum';
import { CostsSimulationRecapResponse } from 'src/app/pages/private/pages/company/pages/documents/pages/offert/pages/offert-detail/pages/offert-simulation/services/offert-simulation.service';
import { UserWorkerProfileService } from 'src/app/pages/private/pages/worker/pages/user/services/user-worker-profile.service';
import { WorkerStatus } from 'src/app/core/auth/interfaces/worker.model';
import { ContractService } from '../../../../pages/private/pages/company/pages/messages/services/contract.service';
import { PaymentInit } from '../../../../pages/private/pages/company/pages/messages/interfaces/payment-init';

@UntilDestroy()
@Component({
  selector: 'app-availability-preview',
  templateUrl: './availability-preview.component.html',
  styleUrls: ['./availability-preview.component.scss'],
})
export class AvailabilityPreviewComponent implements OnInit {
  @ViewChild('templateModalPrivacy') templateModalPrivacy: TemplateRef<any>;
  @ViewChild('templateWorkerNotContractualizable') templateWorkerNotContractualizable: TemplateRef<any>;


  @Input() userType: UserType;
  @Input() finalContractSummary: any;//CostsSimulationRecapResponse;
  @Output() goBack: EventEmitter<boolean> = new EventEmitter();
  @Output() onConfirm: EventEmitter<boolean> = new EventEmitter();
  @Output() onAccept: EventEmitter<CostsSimulationRecapResponse> = new EventEmitter();
  @Output() onReject: EventEmitter<CostsSimulationRecapResponse> = new EventEmitter();
  //finalContractSummary: any;//ContractFinalSummary;
  timeSelectForm: Form;
  riskboardId: string;
  proposalUuid: string;
  paymentType: any;//PaymentTypeEnum;
  workerContractualizable: boolean
  modalNotContractualizableInstance: any
  termsForm = new FormGroup({
    termsFlag: new FormControl(false, Validators.requiredTrue),
  });

  public get userTypeEnum() {
    return UserType;
  }

  constructor(
    private dialogService: TuiDialogService,
    //private riskService: RiskService,
    private loading: NgxSpinnerService,
    private router: Router,
    private route: ActivatedRoute,
    private contractService: ContractService,
    private i18n: TranslateService,
    private toastrService: ToastrService,
    private userWorkerProfileService: UserWorkerProfileService
  ) {}

  ngOnInit() {
    if(this.userType == UserType.WORKER)
      this.userWorkerProfileService.getWorkerProfile().subscribe(e => this.workerContractualizable = e.status == WorkerStatus.CONTRACTUALIZABLE)
  }

  goContract(){
    this.router.navigate(["private", "azienda", "documenti", "contract", this.finalContractSummary.contractWnId, "view"])
  }

  onViewMore = () => {
    this.dialogService
      .open(this.templateModalPrivacy, {
        dismissible: false,
        size: 'l',
        label: this.i18n.instant(
          `PRIVATE.COMPANY.MESSAGES.REQUEST_CONTRACT.PAYMENT.BOX_CONDITION.TITLE`
        ),
      })
      .subscribe();
  };

  viewTerms() {
    window.open(`${environment.privacy.term_pay}`, '_blank');
  }

  onRejectEvent(){
    if(!this.workerContractualizable){
      this.modalNotContractualizableInstance = this.dialogService
      .open(this.templateWorkerNotContractualizable, {
        dismissible: false,
        size: 'l',
        label: this.i18n.instant(
          `PRIVATE.COMMON.AVAILABILITY.NOT_CONTRACTUALIZABLE.TITLE`
        ),
      })
      .subscribe();
    }else{
      this.onReject.next(this.finalContractSummary);
    }
  }

  onAcceptEvent(){
    if(!this.workerContractualizable){
      this.modalNotContractualizableInstance = this.dialogService
      .open(this.templateWorkerNotContractualizable, {
        dismissible: false,
        size: 'l',
        label: this.i18n.instant(
          `PRIVATE.COMMON.AVAILABILITY.NOT_CONTRACTUALIZABLE.TITLE`
        ),
        closeable: false
      })
      .subscribe();
    }else{
      this.onAccept.next(this.finalContractSummary);
    }
  }

  redirectToProfile(){
    this.modalNotContractualizableInstance.unsubscribe();
    this.router.navigate(["home"])
  }

  onGoPayment(){
    if (this.finalContractSummary.paymentMode === PaymentTypeEnum.PAGAMENTO_ANTICIPATO) {
      this.loading.show();
      this.contractService
        .initPaymentNew(this.finalContractSummary.id)
        .pipe(
          catchError((err) => {
            this.loading.hide();
            return throwError(err);
          }),
          untilDestroyed(this)
        )
        .subscribe((result: PaymentInit) => {

          window.open(
            // TODO controllare nuova stringa per il pagamento
            `${result.url}`,
            //`https://sandbox.gestpay.net/pagam/pagam.aspx?a=${result.shopLogin}&b=${result.cryptDecryptString}`,
            '_self'
          );
        });
    } else {
      this.toastrService.error(
        this.i18n.instant(
          'PRIVATE.COMPANY.MESSAGES.REQUEST_CONTRACT.PAYMENT.INIT.ERROR.MESSAGE'
        ),
        this.i18n.instant(
          'PRIVATE.COMPANY.MESSAGES.REQUEST_CONTRACT.PAYMENT.INIT.ERROR.TITLE'
        )
      );
    }
  }


}
