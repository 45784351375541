import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { ApiClientService as ApiClient } from 'src/app/api-client.service';
import {
  Form,
  SelectInput,
  TextInput,
  ValidationStatus,
} from 'src/app/shared/form';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-homepage-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit {
  @ViewChild('contactForm') contactForm: ElementRef;
  @Input() isFAQ: boolean = false;

  contactSalesForceUrl: string = environment.salesformContactFormSubmit;
  contactOrgId: string = environment.salesformContactOrgId;
  contactFormSentParam = 'salesforceFormSent';
  returnUrl: string = window.location.href + '?salesforceFormSent=true';
  askForm: Form;
  privacyForm = new UntypedFormGroup({
    privacyFlag: new UntypedFormControl(false, Validators.requiredTrue),
  });
  subject: string;

  constructor(
    private i18n: TranslateService,
    private apiClient: ApiClient,
    private toastr: ToastrService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this._setForm();
    this.route.queryParams.subscribe((params) => {
      if (!!params[this.contactFormSentParam])
        this.toastr.success('Richiesta di contatto mandata con successo!');
    });
  }

  send() {
    if (environment.salesforceContact) {
      this.contactForm.nativeElement.submit();
    } else {
      this.apiClient
        .request('sendSupportRequest', this.askForm.value)
        .subscribe(() => {
          this.toastr.success(this.i18n.instant('PUBLIC.SUPPORT.SENT'));
          this.askForm.reset();
        });
    }
  }

  _setForm() {
    this.askForm = new Form({
      header: {
        show: false,
      },
      controls: {
        name: new TextInput({
          type: 'text',
          size: '12|12|12|6|6',
          required: true,
          validationStatus: [ValidationStatus.ERROR.REQUIRED],
          label: 'HOME.CONTACT.FORM.NAME.LABEL',
          placeholder: 'HOME.CONTACT.FORM.NAME.PLACEHOLDER',
        }),
        email: new TextInput({
          type: 'email',
          required: true,
          validationStatus: [
            ValidationStatus.ERROR.REQUIRED,
            ValidationStatus.ERROR.CUSTOM(
              (control) => control.getError('email'),
              {
                text: this.i18n.instant('VALIDATIONS.EMAIL'),
              }
            ),
          ],
          label: 'HOME.CONTACT.FORM.EMAIL.LABEL',
          placeholder: 'HOME.CONTACT.FORM.EMAIL.PLACEHOLDER',
          validatorOrOpts: [Validators.email],
        }),
        phone: new TextInput({
          type: 'tel',
          required: true,
          validationStatus: [
            ValidationStatus.ERROR.REQUIRED,
            ValidationStatus.ERROR.CUSTOM(
              (control) => control.getError('pattern'),
              {
                text: this.i18n.instant('VALIDATIONS.TELEFONO'),
              }
            ),
          ],
          size: '12|12|12|6|6',
          label: 'HOME.CONTACT.FORM.PHONE_NUMBER.LABEL',
          placeholder: 'HOME.CONTACT.FORM.PHONE_NUMBER.PLACEHOLDER',
          pattern: '^[0-9]*',
        }),
        subjectSelect: new SelectInput<string, string>({
          required: true,
          options: of([
            {
              cod: this.i18n.instant(
                'HOME.CONTACT.FORM.SUBJECT.SUBJECT_OPTIONS.OPTION_1'
              ),
              des: this.i18n.instant(
                'HOME.CONTACT.FORM.SUBJECT.SUBJECT_OPTIONS.OPTION_1'
              ),
            },
            {
              cod: this.i18n.instant(
                'HOME.CONTACT.FORM.SUBJECT.SUBJECT_OPTIONS.OPTION_2'
              ),
              des: this.i18n.instant(
                'HOME.CONTACT.FORM.SUBJECT.SUBJECT_OPTIONS.OPTION_2'
              ),
            },
            {
              cod: this.i18n.instant(
                'HOME.CONTACT.FORM.SUBJECT.SUBJECT_OPTIONS.OPTION_3'
              ),
              des: this.i18n.instant(
                'HOME.CONTACT.FORM.SUBJECT.SUBJECT_OPTIONS.OPTION_3'
              ),
            },
            {
              cod: this.i18n.instant(
                'HOME.CONTACT.FORM.SUBJECT.SUBJECT_OPTIONS.OPTION_4'
              ),
              des: this.i18n.instant(
                'HOME.CONTACT.FORM.SUBJECT.SUBJECT_OPTIONS.OPTION_4'
              ),
            },
            {
              cod: this.i18n.instant(
                'HOME.CONTACT.FORM.SUBJECT.SUBJECT_OPTIONS.OPTION_5'
              ),
              des: this.i18n.instant(
                'HOME.CONTACT.FORM.SUBJECT.SUBJECT_OPTIONS.OPTION_5'
              ),
            },
            {
              cod: this.i18n.instant(
                'HOME.CONTACT.FORM.SUBJECT.SUBJECT_OPTIONS.OPTION_6'
              ),
              des: this.i18n.instant(
                'HOME.CONTACT.FORM.SUBJECT.SUBJECT_OPTIONS.OPTION_6'
              ),
            },
          ]),
          validationStatus: [ValidationStatus.ERROR.REQUIRED],
          label: 'HOME.CONTACT.FORM.SUBJECT.LABEL',
          placeholder: 'HOME.CONTACT.FORM.SUBJECT.PLACEHOLDER',
          bindValue: false,
          valueChange: (value: any) => {
            if (value) {
              this.askForm
                .get('subjectSelect')
                .setValue(value, { emitEvent: false });
              this.subject = value.des;
            }
          },
        }),
        description: new TextInput({
          type: 'text',
          required: true,
          validationStatus: [ValidationStatus.ERROR.REQUIRED],
          size: '12|12|12|6|6',
          label: 'HOME.CONTACT.FORM.MESSAGE.LABEL',
          placeholder: 'HOME.CONTACT.FORM.MESSAGE.PLACEHOLDER',
        }),
      },
    });
  }

  viewPrivacy() {
    window.open(`${environment.privacy.contact_form}`, '_blank');
  }
}