<div class="search-container my-4 row px-xl-2">
  <form [formGroup]="filterForm" (ngSubmit)="onSubmit()" *ngIf="filterForm">
    <div class="filter-result-container">
      <div class="filter-container">
        <div class="upper-filter-container">
          <div class="container m-auto">
            <div class="mt-3">
              <!-- WHAT -->
              <div class="what-col">
                <app-textbox-input
                  [control]="filterForm.get('what') | castTextInput"
                  [name]="'what'"
                ></app-textbox-input>
              </div>
              <!-- WHERE -->
              <div class="where-col">
                <app-select-virtual-input
                  [control]="filterForm.get('where') | castSelectInput"
                >
                </app-select-virtual-input>
              </div>
              <!-- DISTANCE -->
              <div class="distance d-lg-grid gap-2 d-sm-flex">
                <app-input-label
                  class="contrast-color"
                  [field]="filterForm.get('distance')"
                  [name]="filterForm.get('distance').label"
                  [filter]="false"
                ></app-input-label>
                <app-slider-input
                  [control]="filterForm.get('distance') | castSliderInput"
                ></app-slider-input>
              </div>
              <!-- SEARCH BTN -->
              <div class="search-button d-lg-grid gap-2 d-sm-flex">
                <button
                  type="submit"
                  class="btn btn-green-outline"
                  [disabled]="
                    !filterForm.get('what').value &&
                    !filterForm.get('where').value
                  "
                >
                  <tui-svg src="tuiIconSearch"></tui-svg>
                  {{
                    "PRIVATE.COMPANY.RECENT_SEARCH.FORM_TOP.BUTTON" | translate
                  }}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="lower-filter-container my-3">
          <div class="container m-auto">
            <tui-accordion>
              <tui-accordion-item [size]="'s'" [open]="additionalFilters">
                <div class="filter-label">
                  {{ "SEARCH.FILTER.LABEL" | translate }}
                  <span
                    class="filter-text"
                    [innerHtml]="'SEARCH.FILTER.TEXT' | translate"
                  >
                  </span>
                </div>
                <ng-template tuiAccordionItemContent>
                  <!-- EXTRA KEYWORD -->
                  <div class="extra-keyword">
                    <app-textbox-input
                      [control]="filterForm.get('extraKeyword') | castTextInput"
                      [name]="'extraKeyword'"
                    ></app-textbox-input>
                  </div>
                  <!-- SECTOR -->
                  <app-input-label
                    [field]="filterForm.get('sector')"
                    [name]="'sector'"
                    [filter]="false"
                  >
                  </app-input-label>
                  <app-select-input
                    [control]="filterForm.get('sector') | castSelectInput"
                  ></app-select-input>
                  <!-- EXPERIENCE IN INPLACE -->
                  <app-input-label
                    [field]="filterForm.get('previousExperiencesInInplace')"
                    [name]="'previousExperience'"
                    [filter]="false"
                  ></app-input-label>
                  <app-select-input
                    [control]="
                      filterForm.get('previousExperiencesInInplace')
                        | castSelectInput
                    "
                  ></app-select-input>
                  <!-- EMPLOYED -->
                  <app-input-label
                    [field]="filterForm.get('status')"
                    [name]="'status'"
                    [filter]="false"
                  ></app-input-label>
                  <app-select-input
                    [control]="filterForm.get('status') | castSelectInput"
                  ></app-select-input>
                  <!-- INSTRUCTION -->
                  <app-input-label
                    [field]="filterForm.get('educationLevel')"
                    [name]="'educationLevel'"
                    [filter]="false"
                  ></app-input-label>
                  <app-select-input
                    [control]="
                      filterForm.get('educationLevel') | castSelectInput
                    "
                  ></app-select-input>
                  <!-- LANGUAGE -->
                  <div class="language-container row">
                    <div class="language-dropdown col-6">
                      <app-input-label
                        [field]="filterForm.get('language')"
                        [name]="'language'"
                        [filter]="false"
                      ></app-input-label>
                      <app-select-input
                        [control]="filterForm.get('language') | castSelectInput"
                      ></app-select-input>
                    </div>
                    <div class="language-level-dropdown dropdown col-6">
                      <app-input-label
                        [field]="filterForm.get('languageLevel')"
                        [name]="'languageLevel'"
                        [filter]="false"
                      ></app-input-label>
                      <app-select-input
                        [control]="
                          filterForm.get('languageLevel') | castSelectInput
                        "
                      ></app-select-input>
                    </div>
                  </div>
                  <!-- FILTERS BTN -->
                  <div class="search-button d-lg-grid gap-2 d-sm-flex">
                    <button
                      type="submit"
                      class="btn btn-green-outline"
                      [disabled]="!keyword && !filterForm.get('where').value"
                    >
                      <tui-svg
                        class="filter-icon"
                        src="tuiIconSearch"
                      ></tui-svg>
                      {{ "SEARCH.FORM.FILTER_BUTTON" | translate }}
                    </button>
                  </div>
                </ng-template>
              </tui-accordion-item>
            </tui-accordion>

            <ng-container *ngIf="!isPublic && suggestedList?.length">
              <!-- SUGGESTED ACCORDION -->
              <tui-accordion
                class="suggested-container suggested-accordion d-flex d-lg-none"
              >
                <tui-accordion-item
                  [size]="'s'"
                  [open]="!!suggestedList?.length"
                >
                  <div class="filter-label">
                    {{ "MENU.COMPANY.ITEM.SUGGESTED_CANDIDATES" | translate }}
                    <span
                      class="filter-text"
                      [innerHtml]="
                        'PRIVATE.COMPANY.MY_ANNOUNCEMENTS.SEARCH_CANDIDATES_SUGGESTED'
                          | translate
                      "
                    >
                    </span>
                  </div>
                  <ng-template tuiAccordionItemContent>
                    <div class="" *ngIf="!isPublic && suggestedList?.length">
                      <div class="suggested-card-list">
                        <div
                          class="card card-body col-6 suggested-card d-flex flex-column justify-content-center align-items-center mt-3"
                          *ngFor="
                            let candidate of suggestedList | slice : 0 : 6
                          "
                          (click)="goToDetail(candidate?.id)"
                        >
                          <div class="title">
                            {{ candidate?.name | titlecase }}
                          </div>
                          <div class="sector">
                            <div class="label">
                              {{ ("SEARCH.INFO.SECTOR" | translate) + ": " }}
                              <span class="info">
                                {{
                                  "MAPPINGS.sectors." +
                                    (candidate?.sector
                                      ? candidate.sector
                                      : "ALTRO") | translate
                                }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <button
                        class="btn btn-green btn-suggested mx-auto"
                        [routerLink]="[
                          '/private/azienda/annunci/candidati-suggeriti'
                        ]"
                        *ngIf="suggestedList.length > 6"
                      >
                        {{
                          "PRIVATE.COMPANY.MY_ANNOUNCEMENTS.BTN_TO_SUGGESTED"
                            | translate
                        }}
                      </button>
                    </div>
                  </ng-template>
                </tui-accordion-item>
              </tui-accordion>

              <!-- SUGGESTED CARDS -->
              <div class="suggested-container d-none d-lg-flex">
                <p
                  class="mt-4 suggested-text"
                  [innerHtml]="
                    'PRIVATE.COMPANY.MY_ANNOUNCEMENTS.SEARCH_CANDIDATES_SUGGESTED'
                      | translate
                  "
                ></p>
                <div class="suggested-card-list">
                  <div
                    class="card card-body col-6 suggested-card d-flex flex-column justify-content-center align-items-center mt-3"
                    *ngFor="let candidate of suggestedList | slice : 0 : 6"
                    (click)="goToDetail(candidate?.id)"
                  >
                    <div class="title">{{ candidate?.name | titlecase }}</div>
                    <div class="sector">
                      <div class="label">
                        {{ ("SEARCH.INFO.SECTOR" | translate) + ": " }}
                        <span class="info">
                          {{
                            "MAPPINGS.sectors." +
                              (candidate?.sector ? candidate.sector : "ALTRO")
                              | translate
                          }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  class="btn btn-green btn-suggested mt-3"
                  [routerLink]="[
                    '/private/azienda/annunci/candidati-suggeriti'
                  ]"
                >
                  {{
                    "PRIVATE.COMPANY.MY_ANNOUNCEMENTS.BTN_TO_SUGGESTED"
                      | translate
                  }}
                </button>
              </div>
            </ng-container>
          </div>
        </div>
      </div>

      <div class="active-filter-result-container my-lg-3" #candidateListRef>
        <div class="active-filter-container" *ngIf="activeFilter?.length > 0">
          <div class="container row m-auto">
            <div class="col-12 mb-2 ps-0" *ngIf="activeFilter?.length > 0">
              <button
                *ngFor="let filter of activeFilter"
                (click)="clearFilter(filter)"
                type="button"
              >
                {{ filter?.label | translate }}:
                <span>{{ filter?.value }}</span>
                <tui-svg class="icon" src="tuiIconCloseLarge"></tui-svg>
              </button>
            </div>
            <div class="col-12 mb-2 ps-0" *ngIf="activeFilter?.length > 0">
              <button
                class="button clear"
                type="button"
                (click)="clearAllFilters()"
              >
                {{ "SEARCH.FILTER.CLEAR" | translate }}
                <tui-svg class="icon" src="tuiIconCloseLarge"></tui-svg>
              </button>
            </div>
          </div>
        </div>

        <div class="search-body-container">
          <div class="container m-auto">
            <div class="col-12 left">
              <div class="order-container row justify-content-end mb-sm-3 mb-4">
                <div
                  class="col-12 col-sm-8 left m-auto text-center text-sm-start"
                >
                  <span class="records_found"
                    >{{ totalMatches > 300 ? "300+" : totalMatches }}
                    {{ "SEARCH.WORKER_RECORDS_FOUND" | translate }}
                  </span>
                </div>
                <div class="col-12 col-sm-4 left">
                  <app-input-label
                    [field]="filterForm.get('orderBy')"
                    [name]="'orderBy'"
                    [filter]="false"
                  >
                  </app-input-label>
                  <app-select-input
                    [control]="filterForm.get('orderBy') | castSelectInput"
                  ></app-select-input>
                </div>
              </div>
              <div *ngIf="candidateList?.length">
                <div
                  class="card card-body"
                  *ngFor="
                    let candidate of candidateList
                      | slice : size * pageNumber : size * (pageNumber + 1)
                  "
                >
                  <div class="row">
                    <div class="col-12 col-md-8 left">
                      <div class="row">
                        <div
                          class="col-12 col-sm-6 title"
                          (click)="goToDetail(candidate?.id)"
                        >
                          {{ candidate?.name | titlecase }}
                        </div>

                        <div class="col-12 col-sm-6 update text-start">
                          <div class="updated">
                            {{ "SEARCH.INFO.LAST_UPDATE" | translate }}
                            {{ candidate?.updated | date : "dd/MM/yyyy" }}
                          </div>
                          <div *ngIf="keyword" class="comp">
                            {{ candidate?.compatibility }}% compatibile
                          </div>
                        </div>
                        <!-- ADDRESS -->
                        <div class="col-12 col-sm-6 address">
                          <div class="label">
                            {{ "SEARCH.INFO.ADDRESS" | translate }}
                          </div>
                          <div
                            class="info"
                            *ngIf="
                              candidate?.address?.municipality ||
                                candidate?.address?.region;
                              else noAddress
                            "
                          >
                            {{
                              candidate?.address?.municipality
                                ? ("MAPPINGS.municipalities." +
                                    candidate.address.municipality
                                  | translate
                                  | titlecase)
                                : null
                            }}
                            {{
                              candidate?.address?.region
                                ? (candidate?.address?.municipality
                                    ? " - "
                                    : "") +
                                  ("MAPPINGS.regions." +
                                    candidate.address.region | translate)
                                : null
                            }}
                            {{
                              candidate?.address?.country &&
                              candidate?.address?.country != "ITALIA"
                                ? " , " +
                                  ("MAPPINGS.countries." +
                                    candidate.address.country | translate)
                                : null
                            }}
                          </div>

                          <!-- JOB LOCATION PREFERENCES -->
                          <div
                            class="address job-location"
                            *ngIf="candidate?.jobLocationPreferences.length"
                          >
                            <ng-container
                              *ngIf="
                                this.filterForm.get('orderBy').value !==
                                  OrderBy.DISTANZA ||
                                  !this.filterForm.get('where').value;
                                else matchedLocation
                              "
                            >
                              <div class="label">
                                {{
                                  candidate?.jobLocationPreferences?.length > 1
                                    ? ("SEARCH.INFO.JOB_LOCATION_PREFERENCES"
                                      | translate)
                                    : ("SEARCH.INFO.JOB_LOCATION_PREFERENCE"
                                      | translate)
                                }}
                              </div>
                              <div
                                class="info"
                                *ngFor="
                                  let preference of candidate?.jobLocationPreferences
                                    | slice : 0 : 3
                                "
                              >
                                {{
                                  preference?.municipality
                                    ? ("MAPPINGS.municipalities." +
                                        preference?.municipality
                                      | translate
                                      | titlecase)
                                    : null
                                }}
                                {{
                                  preference?.region
                                    ? (preference?.municipality ? " - " : "") +
                                      ("MAPPINGS.regions." + preference?.region
                                        | translate)
                                    : null
                                }}
                              </div>
                            </ng-container>
                            <ng-template #matchedLocation>
                              <div
                                class="label"
                                *ngIf="candidate?.matchedLocation"
                              >
                                {{
                                  "SEARCH.INFO.JOB_LOCATION_PREFERENCE"
                                    | translate
                                }}
                              </div>
                              <div class="info">
                                {{
                                  candidate?.matchedLocation?.location
                                    ?.municipality
                                    ? ("MAPPINGS.municipalities." +
                                        candidate?.matchedLocation?.location
                                          ?.municipality
                                      | translate
                                      | titlecase)
                                    : null
                                }}
                                {{
                                  candidate?.matchedLocation?.location?.region
                                    ? (candidate?.matchedLocation?.location
                                        ?.municipality
                                        ? " - "
                                        : "") +
                                      ("MAPPINGS.regions." +
                                        candidate?.matchedLocation?.location
                                          ?.region
                                        | translate
                                        | titlecase)
                                    : null
                                }}
                                {{
                                  candidate?.matchedLocation?.location
                                    ?.country &&
                                  candidate?.matchedLocation?.location
                                    ?.country !== "ITALIA"
                                    ? (candidate?.matchedLocation?.location
                                        ?.region
                                        ? " ,"
                                        : "") +
                                      ("MAPPINGS.regions." +
                                        candidate?.matchedLocation?.location
                                          ?.region | translate)
                                    : null
                                }}
                              </div>
                            </ng-template>
                          </div>
                        </div>

                        <div class="col-12 col-sm-6 sector">
                          <div class="label">
                            {{ "SEARCH.INFO.SECTOR" | translate }}
                          </div>
                          <div class="info">
                            {{
                              "MAPPINGS.sectors." +
                                (candidate?.sector ? candidate.sector : "ALTRO")
                                | translate
                            }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="col-12 col-md-4 right"
                      *ngIf="user && user?.roleType !== roleType.COMPANY"
                    >
                      <div
                        class="text-center my-0 text-md-start"
                        [ngClass]="{
                          available: !candidate?.employed,
                          notAvailable: candidate?.employed
                        }"
                      >
                        <span class="label" *ngIf="!candidate?.employed">{{
                          "SEARCH.INFO.NOT_HIRED" | translate
                        }}</span>
                        <span class="label" *ngIf="candidate?.employed">{{
                          "SEARCH.INFO.HIRED" | translate
                        }}</span>
                      </div>
                      <div class="exp text-center my-0 text-md-start">
                        <ng-container
                          *ngIf="!candidate?.previousExperiencesInInplace"
                        >
                          <tui-svg
                            class="icon"
                            src="tuiIconAttention"
                          ></tui-svg>
                          <span class="label">{{
                            "SEARCH.INFO.NOT_EXPIRIENCE" | translate
                          }}</span>
                        </ng-container>
                        <ng-container
                          *ngIf="candidate?.previousExperiencesInInplace"
                        >
                          <tui-svg
                            class="icon icon-check"
                            src="tuiIconCheck"
                          ></tui-svg>
                          <span class="label">{{
                            "SEARCH.INFO.EXPIRIENCE" | translate
                          }}</span>
                        </ng-container>
                      </div>

                      <div
                        class="address-distance text-center my-0 text-md-start"
                        *ngIf="
                          this.filterForm.get('where').value &&
                          candidate?.matchedLocation
                        "
                      >
                        <tui-svg class="icon" src="tuiIconPin"></tui-svg>
                        <span
                          class="label"
                          *ngIf="
                            candidate?.matchedLocation?.distanceInKm ||
                              candidate?.matchedLocation?.distanceInKm == 0;
                            else noDistance
                          "
                        >
                          {{
                            "SEARCH.INFO.JOB_LOCATION_DISTANCE"
                              | translate
                                : {
                                    distance:
                                      candidate?.matchedLocation?.distanceInKm
                                      | number : "1.0-2"
                                  }
                          }}
                        </span>
                        <ng-template #noDistance>
                          <span
                            class="label"
                            *ngIf="
                              !candidate?.matchedLocation?.distanceInKm &&
                              candidate?.matchedLocation?.distanceInKm != 0
                            "
                            >{{ "SEARCH.INFO.NO_DISTANCE" | translate }}
                          </span>
                        </ng-template>
                      </div>
                    </div>

                    <div
                      class="col-12 col-md-4 right"
                      *ngIf="isPublic || user?.userRole !== roleType.COMPANY"
                    >
                      <div
                        class="card card-body yellow-body ms-0"
                        (click)="toLogin()"
                      >
                        <div class="title">
                          {{
                            "SEARCH.COMPLETE_PROFILE.YELLOW_BOX.TITLE"
                              | translate
                          }}
                        </div>
                        <div class="text px-2">
                          {{
                            "SEARCH.COMPLETE_PROFILE.YELLOW_BOX.TEXT"
                              | translate
                          }}
                        </div>
                      </div>
                    </div>

                    <div
                      class="col-12 bottom d-flex flex-column flex-md-row flex-wrap gap-2 align-items-center justify-content-start"
                    >
                      <button
                        type="button"
                        class="btn btn-green me-2 me-md-0"
                        (click)="availabilityRequest(candidate?.id)"
                      >
                        <tui-svg
                          src="tuiIconTimeLarge"
                          style="margin-right: 5px"
                        ></tui-svg>
                        {{ "SEARCH.BUTTONS.AVAILABLE" | translate }}
                      </button>
                      <button
                        type="button"
                        class="btn btn-green"
                        (click)="goToDetail(candidate?.id)"
                      >
                        <tui-svg src="tuiIconClipboard"></tui-svg>
                        {{ "SEARCH.BUTTONS.SHOW" | translate }}
                      </button>
                      <button
                      *ngIf="!isPublic && candidate?.aiSummary;"
                        type="button"
                        class="btn btn-green"
                        (click)="downloadDossier(candidate?.id, candidate?.name)"
                      >
                        <tui-svg src="tuiIconClipboard"></tui-svg>
                        {{ "SEARCH.TABS.DOWNLOAD_CV" | translate }}
                      </button>
                      <div
                        class="ms-0 ms-md-auto last-seen mt-2 mt-md-0 mb-1 mb-md-0"
                        *ngIf="candidate?.lastSeen"
                      >
                        {{ "SEARCH.INFO.LAST_SEEN" | translate }}
                        {{ candidate?.lastSeen | date : "dd/MM/yyyy" }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!--div
                class="card card-body"
                *ngIf="!candidateList && !candidateList?.length"
              >
                {{ "SEARCH.RECENT.NO_RESULT" | translate }}
              </div-->

              <div
                class="card card-body"
                *ngIf="(candidateList && !candidateList?.length) || (!candidateList && !candidateList?.length)"
              >
                <p>
                  <b>{{ "SEARCH.NO_RESULT.TITLE" | translate }}</b>
                  </p>
                <p>
                 <span>
                  {{'SEARCH.NO_RESULT.TEXT' | translate}}
                  <br>
                  <!--span>
                    <a class="white" href="mailto:support@inplace.it">
                      {{ "SEARCH.NO_RESULT.EMAIL" | translate }}
                    </a>
                  </span-->
                  {{ "SEARCH.NO_RESULT.TEXT_2" | translate }}</span><br>
                </p><p style="margin-bottom: 0">
                      <button style="float:right"
                        (click)="toRegister()"
                        class="btn btn-green"
                        type="button">
                        <tui-svg src="tuiIconEdit2"></tui-svg>
                        {{ "PRIVATE.COMPANY.MENU.BUTTONS.PUBLIC_ANNOUNCE" | translate }}
                      </button>
                    </p>
             
              </div>

              <div class="pager" *ngIf="!isPublic && user; else publicSearch">
                <tui-pagination
                  *ngIf="totalElements > size"
                  [index]="pageNumber"
                  [length]="length"
                  (indexChange)="pageChange($event)"
                ></tui-pagination>
              </div>

              <ng-template #publicSearch>
                <div
                  class="pager"
                  (click)="registerOverlay()"
                  style="cursor: pointer"
                >
                  <tui-pagination
                    style="pointer-events: none"
                    *ngIf="totalMatches > size"
                    [index]="pageNumber"
                    [length]="length"
                  >
                  </tui-pagination>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>

  <!--div class="container">
    <div class="d-flex py-3 my-4 pe-3 justify-content-end bg-grey">
      <button
        (click)="goToCreateAnnouncement()"
        class="btn btn-green"
        type="button"
      >
        <tui-svg src="tuiIconEdit2"></tui-svg>
        {{ "PRIVATE.COMPANY.MENU.BUTTONS.PUBLIC_ANNOUNCE" | translate }}
      </button>
    </div>
  </div-->  
</div>

<ng-template let-observer #completeProfileDialog>
  <p style="font: normal normal medium 16px/19px Roboto">
    {{ "PRIVATE.COMPANY.USER.COMPLETE_PROFILE.DIALOG.TEXT_1" | translate }}
  </p>
  <p style="font: normal normal medium 16px/19px Roboto">
    {{ "PRIVATE.COMPANY.USER.COMPLETE_PROFILE.DIALOG.TEXT_2" | translate }}
  </p>
  <div style="width: max-content">
    <p style="font: normal normal normal 14px/16px Roboto">
      <tui-svg class="icon icon-check" src="tuiIconCheck"></tui-svg>
      {{ "PRIVATE.COMPANY.USER.COMPLETE_PROFILE.DIALOG.INAIL" | translate }}
    </p>
  </div>
  <div style="width: max-content">
    <p style="font: normal normal normal 14px/16px Roboto">
      <tui-svg class="icon icon-check" src="tuiIconCheck"></tui-svg>
      {{ "PRIVATE.COMPANY.USER.COMPLETE_PROFILE.DIALOG.CCNL" | translate }}
    </p>
  </div>

  <div class="my-2">
    <button (click)="toComplete()" class="btn btn-green">
      {{ "PRIVATE.COMPANY.USER.COMPLETE_PROFILE.DIALOG.BUTTON" | translate }}
    </button>
  </div>
</ng-template>

<ng-template #noAddress>
  <span class="info no-address"
    >{{ "SEARCH.INFO.NO_DISTANCE" | translate }}
  </span>
</ng-template>
