import { Routes } from '@angular/router';
import { AuthenticationGuard } from '@core/auth/guards/authentication-guard.service';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule),
  },

  // new pages
  {
    path: 'private',
    canActivate: [AuthenticationGuard],
    children: [
      {
        path: 'candidato',
        loadChildren: () => import('./worker-page/worker-page.module').then(m => m.WorkerPageModule),
      },
      {
        path: 'azienda',
        loadChildren: () => import('./company-page/company-page.module').then(m => m.CompanyPageModule),
      },
    ],
  },
  {
    path: '**',
    loadChildren: () => import('@pages/not-found/not-found.module').then(m => m.NotFoundModule),
  },
];
