import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { NgxSpinnerService } from 'ngx-spinner';
import { TableColumn } from 'src/app/shared/table/models/column.model';
import { formatDate } from '@angular/common';
import { UserType } from 'src/app/core/interfaces/user-type.enum';
import { InterviewListItem, InterviewStatus } from 'src/app/shared/interfaces/interview.model';
import { InterviewsService } from 'src/app/shared/services/interviews.service';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { ServerPageableDataSource } from 'src/app/shared/table/models/server-pageable-data-source';
import { environment } from 'src/environments/environment';

@UntilDestroy()
@Component({
  selector: 'app-availability-table',
  templateUrl: './availability-table.component.html',
  styleUrls: ['./availability-table.component.scss']
})
export class AvailabilityTableComponent implements AfterViewInit {
  @ViewChild('workerTemplate') workerTemplate: TemplateRef<any>;
  @ViewChild('clientTemplate') clientTemplate: TemplateRef<any>;
  @ViewChild('startTemplate') startTemplate: TemplateRef<any>;
  @ViewChild('endTemplate') endTemplate: TemplateRef<any>;
  @ViewChild('statusTemplate') statusTemplate: TemplateRef<any>;
  @ViewChild('createdAtTemplate') createdAtTemplate: TemplateRef<any>;
  @ViewChild('buttonsTemplate') buttonsTemplate: TemplateRef<any>;
  @ViewChild('typeTemplate') typeTemplate: TemplateRef<any>;
  @ViewChild('taskTemplate') taskTemplate: TemplateRef<any>;
  @ViewChild('workPlaceTemplate') workPlaceTemplate: TemplateRef<any>;

  columns: TableColumn[] = []; 
  @Input() dataSource: ServerPageableDataSource<any[]>;

  @Input() pageSize: number = 10;
  currentPage:number = 0;
  @Output() onChangePage:  EventEmitter<any> = new EventEmitter(); 

  renewalSuccess = false;
  extensionSuccess = false;

  @Input() userType: UserType;  
  get userTypeEnum() {
    return UserType;
  }
  get InterviewStatus() {
    return InterviewStatus;
  }

  constructor(
    private route: ActivatedRoute,
    private i18n: TranslateService,
    private loading: NgxSpinnerService,
    private interviewsService: InterviewsService,
    private toastrService: ToastrService,
    private router: Router) {
      
  }

  ngAfterViewInit(): void {
    var columns = [
      /*
      {
        name: this.i18n.instant('PRIVATE.COMMON.INTERVIEWS.TABLE.LAST_EDIT'),
        prop: 'updated',
        pipe: {
          transform: (value: any) => {
            var d = new Date(0);
            d.setUTCSeconds(value);
            return formatDate(d, 'dd/MM/yyyy HH:mm', 'it-IT')
          }
        },
        cellClass: 'fs-6',
      },      
      */
      {
        name: this.i18n.instant('PRIVATE.COMMON.AVAILABILITY.TABLE_COLUMNS.TYPE'),
        prop: 'type',
        pipe: {
          transform: (value: string) =>
            this.i18n.instant('PRIVATE.COMMON.AVAILABILITY.TYPES.'+value),
        },
        cellClass: 'fs-6',
      },
      {
        name: this.i18n.instant('PRIVATE.COMMON.AVAILABILITY.TABLE_COLUMNS.WORKER'),
        prop: 'worker',
        cellClass: 'fs-6',
        cellTemplate: this.workerTemplate,
      },  
      {
        name: this.i18n.instant('PRIVATE.COMMON.AVAILABILITY.TABLE_COLUMNS.CLIENT'),
        prop: 'client',
        cellClass: 'fs-6',
        cellTemplate: this.clientTemplate,
      },  
      {
        name: this.i18n.instant('PRIVATE.COMMON.AVAILABILITY.TABLE_COLUMNS.TASK'),
        cellClass: 'fs-6',
        cellTemplate: this.taskTemplate,
      }, 
      {
        name: this.i18n.instant('PRIVATE.COMMON.AVAILABILITY.TABLE_COLUMNS.WORKPLACE'),
        cellClass: 'fs-6',
        cellTemplate: this.workPlaceTemplate,
      }, 
      {
        name: this.i18n.instant('PRIVATE.COMMON.AVAILABILITY.TABLE_COLUMNS.STATUS'),
        prop: 'status',
        pipe: {
          transform: (value: string) =>
            this.i18n.instant('PRIVATE.COMMON.AVAILABILITY.STATUS.'+value),
        },
        cellClass: 'fs-6',
      },
      {
        name: this.i18n.instant('PRIVATE.COMMON.AVAILABILITY.TABLE_COLUMNS.START'),
        prop: 'start',
        cellTemplate: this.startTemplate,
        pipe: {
          transform: (value: any) => {
            var d = new Date(0);
            d.setUTCSeconds(value);
            return formatDate(d, 'dd/MM/yyyy HH:mm', 'it-IT')
          }
        },
        cellClass: 'fs-6',
      },  
      {
        name: this.i18n.instant('PRIVATE.COMMON.AVAILABILITY.TABLE_COLUMNS.END'),
        prop: 'end',
        cellTemplate: this.endTemplate,
        pipe: {
          transform: (value: any) => {
            var d = new Date(0);
            d.setUTCSeconds(value);
            return formatDate(d, 'dd/MM/yyyy HH:mm', 'it-IT')
          }
        },
        cellClass: 'fs-6',
      },  

      {
        name: this.i18n.instant('PRIVATE.COMMON.AVAILABILITY.TABLE_COLUMNS.CREATED'),
        prop: 'createdAt',
        cellTemplate: this.createdAtTemplate,
        pipe: {
          transform: (value: any) => {
            var d = new Date(0);
            d.setUTCSeconds(value);
            return formatDate(d, 'dd/MM/yyyy HH:mm', 'it-IT')
          }
        },
        cellClass: 'fs-6',
      },  
      {
        prop: '',
        sortable: false,
        rowDetailIcon: true,
        buttonToggleIcon: true,
        cellClass: 'fs-6',
      },
    ]
    
    if(this.userType === UserType.WORKER){
      columns.splice(1, 1)
    }

    if(this.userType === UserType.COMPANY){
      columns.splice(2, 1)
    }

    this.columns = columns;
  }

  pagerChange($event){
    this.currentPage = $event;
    this.onChangePage.emit($event)
  }

  cancelInterview(row: InterviewListItem) {
    this.loading.show();
    this.interviewsService.cancelInterviewWorker(row.id, this.userType)
      .pipe(
        catchError((err) => {
          this.loading.hide();
          return throwError(err);
        })
      )
      .subscribe(() => {
        this.loading.hide();
        this.dataSource.refresh();
        this.toastrService.success(
          this.i18n.instant('PRIVATE.COMMON.INTERVIEWS.CANCEL_MESSAGE.TITLE'),
          this.i18n.instant('PRIVATE.COMMON.INTERVIEWS.CANCEL_MESSAGE.MESSAGE')
        );
      });   
  }

  confirmInterview(row: any) {
    this.loading.show();
    this.interviewsService.confirmInterviewWorker(row.id)
      .pipe(
        catchError((err) => {
          this.loading.hide();
          return throwError(err);
        })
      )
      .subscribe(() => {
        this.loading.hide();
        this.dataSource.refresh();
        this.toastrService.success(
          this.i18n.instant('PRIVATE.COMMON.INTERVIEWS.CONFIRM_MESSAGE.TITLE'),
          this.i18n.instant('PRIVATE.COMMON.INTERVIEWS.CONFIRM_MESSAGE.MESSAGE')
        );
      }); 
  }
  
  rejectInterview(row: any) {
    this.loading.show();
    this.interviewsService.rejectInterviewWorker(row.id)
      .pipe(
        catchError((err) => {
          this.loading.hide();
          return throwError(err);
        })
      )
      .subscribe(() => {
        this.loading.hide();
        this.dataSource.refresh();
        this.toastrService.success(
          this.i18n.instant('PRIVATE.COMMON.INTERVIEWS.REJECT_MESSAGE.TITLE'),
          this.i18n.instant('PRIVATE.COMMON.INTERVIEWS.REJECT_MESSAGE.MESSAGE')
        );
      });
  }

  goToCandidate(id: string) {
    this.router.navigate(['private','azienda','cerca-candidati','search-detail',id,]);
  }

  goToClient(id: string) {
    this.router.navigate(['private','azienda','cerca-candidati','search-detail',id,]);
  }

  goToLink(link: string) {
    window.location.href = link
    // window.open(link)
    // this.router.navigate(['private','azienda','cerca-candidati','search-detail',id,]);
  }

  viewPrivacy() {
    window.open(`${environment.privacy.interview}`, '_blank');
  }


  onRowDetailClick(event){
    console.log(event)
    if(this.userType === UserType.WORKER){
      this.router.navigate(['private','candidato','availability','availability-list', event.row.id])
    }

    if(this.userType === UserType.COMPANY){
      this.router.navigate(['private','azienda','availability','availability-list', event.row.id])
    }
    
  }

}
