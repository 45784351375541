import { registerLocaleData } from '@angular/common';
// Firebase services + environment module
import { HttpClient } from '@angular/common/http';
import localeIt from '@angular/common/locales/it';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TuiDialogModule, TuiRootModule } from '@taiga-ui/core';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { PixelModule } from 'ngx-pixel';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AppComponent } from './app.component';
import { AppConfig } from './app.config';
import { routes } from './app.routes';
import { CoreModule } from './core/core.module';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { SharedModule } from './shared/shared.module';
import { UrlService } from './shared/url/url.service';
import { ThemeModule } from './theme/theme.module';

registerLocaleData(localeIt);

const TAIGA_MODULES = [TuiRootModule, TuiDialogModule];
const FIREBASE_MODULES = [
  AngularFireModule.initializeApp(environment.firebase),
  AngularFireAuthModule,
  AngularFirestoreModule,
  AngularFireStorageModule,
  AngularFireDatabaseModule,
];

export function httpLoaderFactory(http: HttpClient) {
  const staticL10n = new TranslateHttpLoader(http, '../../assets/i18n/', '.json');
  const dynamicL10n = new TranslateHttpLoader(http, `${environment.baseUrl}/api/l10n/`, '/');
  return {
    getTranslation(lang: string): Observable<any> {
      const o1 = staticL10n.getTranslation(lang);
      const o2 = dynamicL10n.getTranslation(lang).pipe(catchError(() => of({})));
      return combineLatest([o1, o2]).pipe(map(([o3, o4]) => Object.assign({}, o3, o4)));
    },
  };
}

export const AppModule = (appConfig: typeof AppConfig) => {
  @NgModule({
    declarations: [AppComponent, PrivacyComponent],
    imports: [
      RouterModule.forRoot(routes, {
        scrollPositionRestoration: 'enabled',
        anchorScrolling: 'enabled',
        scrollOffset: [0, 64],
      }),
      BrowserModule,
      BrowserAnimationsModule,
      NgxSpinnerModule,
      ...TAIGA_MODULES,
      ...FIREBASE_MODULES,
      ToastrModule.forRoot({
        timeOut: 3000,
        closeButton: true,
      }),
      ThemeModule.forRoot(),
      CoreModule.forRoot(appConfig),
      NgbModule,
      SharedModule,
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: httpLoaderFactory,
          deps: [HttpClient],
        },
      }),
      NgxGoogleAnalyticsModule.forRoot(
        environment.googleTrackingCode,
        null,
        'https://googletagmanager.com/gtm.js?id=' + environment.googleTrackingCode
      ),
      NgxGoogleAnalyticsRouterModule,
      PixelModule.forRoot({ enabled: environment.pixelTrackingEnabled, pixelId: environment.pixelTrackingCode }),
    ],
    bootstrap: [AppComponent],
    providers: [UrlService],
  })
  class Module {}
  return Module;
};
